import { useGlobalState } from '../store'
import {viewGLSL} from "../Functions";

const NFTs = () => {

    const connectedAccount = useGlobalState('connectedAccount')
    const nftBalance = useGlobalState('nftBalance')
    const nfts = useGlobalState('nfts')

    let content
    if (connectedAccount[0] !== '') {
        const items = []
        for (var i=0;i<nfts[0].length;i++) {
            let url = nfts[0][i]['tokenMetadata']['glsl_url']
            items.push(
                <div className="nft-item" key={nfts[0][i]['tokenId']}>
                    {nfts[0][i]['tokenMetadata']['name']}
                    <br /><button type="button" value={url} onClick={e => viewGLSL(e.currentTarget.getAttribute('value'))} className="main-mint-btn">VIEW SHADER</button>
                </div>
            )
        }
        content = <div className="nft-container">{items}</div>
    } else {
        content = <span>PLEASE CONNECT WALLET</span>
    }

    return (
        <div className="viewer">
            YOUR NFTS: {nftBalance[0]}<br />
            {content}
        </div>
    )
}

export default NFTs