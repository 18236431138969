import { getGlobalState,useGlobalState,setGlobalState } from './store'
import { ethers } from 'ethers';

const { ethereum } = window

const contractAddress = "0xce926a3f5626512611f63A3CBb5dcF894C76d13a"

const abi = [
    "function balanceOf(address owner) public view returns (uint256)",
    "function safeMint(address to) public",
    "function tokenOfOwnerByIndex(address owner, uint256 index) public view returns (uint256)",
    "function tokenURI(uint256 index) public view returns (string)",
]

async function connectWallet() {
    try {
        if (!ethereum) return alert('Please install Metamask')
        //const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
        //console.log(accounts)
        //setGlobalState('connectedAccount', accounts[0])
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        setGlobalState('signer', signer)
        let account = await signer.getAddress()
        setGlobalState('connectedAccount', account)
        console.log("Account:", account);
        let contract
        try {
            contract = new ethers.Contract(contractAddress, abi, provider);
        } catch(e) {
            console.log(e)
        }
        setGlobalState('contract', contract)
        console.log("contract:", contract);
        await fetchNFTs()
    } catch (error) {
        //setAlert(JSON.stringify(error), 'red')
    }
}

async function fetchNFTs() {
    const connectedAccount = getGlobalState('connectedAccount')
    const contract = getGlobalState('contract')

    let balance
    try {
        balance = await contract.balanceOf(connectedAccount)
        let nfts = []
        for(var i=0;i<balance.toNumber();i++) {
            let tokenId = await contract.tokenOfOwnerByIndex(connectedAccount, i)
            let tokenUri = await contract.tokenURI(tokenId)
            let tokenMetadata = false
            console.log(tokenUri)
            try {
                let response = await fetch(tokenUri + '?t=' + Date.now());
                tokenMetadata = await response.json();
            } catch (e) {
                console.log(e)
            }
            nfts.push({
                tokenId: tokenId,
                tokenUri: tokenUri,
                tokenMetadata: tokenMetadata
            })
        }
        setGlobalState('nfts', nfts)
    } catch(e) {
        console.log(e)
    }
    setGlobalState('nftBalance', balance.toNumber())
    console.log("balance:", balance.toNumber());
}

async function mint() {
    //setMinting(true);
    const connectedAccount = getGlobalState('connectedAccount')
    const contract = getGlobalState('contract')
    const signer = getGlobalState('signer')

    const tx = await contract.connect(signer).safeMint(connectedAccount)
    console.log(tx)
    const receipt = await tx.wait()
    await fetchNFTs()
    console.log(receipt)
}

async function viewGLSL(url) {
    console.log('view file: ' + url)
    let response = await fetch(url);
    let text = await response.text();
    setGlobalState('shader', text);
}

export {connectWallet,fetchNFTs,mint,viewGLSL}