import { ShaderCanvas } from './components/glslViewer';
import './App.css';
import WalletConnect from "./components/WalletConnect";
import NFTs from "./components/NFTs"

function App() {
  return (
    <div className="app" style={{ textAlign: "center", width: "100vw", height: "100vh", backgroundColor: "black", color: "white" }}>
      <WalletConnect />
      <NFTs />
      <ShaderCanvas />
      <div className="logo"></div>
    </div>
  );
}

export default App;
