import { FC, useEffect, useRef } from "react";
import GlslCanvas from "glslCanvas";
import { useGlobalState } from '../store'

interface ShaderCanvasProps {
  setUniforms?: { [key: string]: string };
}

export const ShaderCanvas: FC<ShaderCanvasProps> = (props): JSX.Element => {

  const shader = useGlobalState('shader')

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const resizer = (
    canvas: HTMLCanvasElement,
    container: HTMLDivElement
  ): void => {
    canvas.width = container.clientWidth + window.devicePixelRatio;
    canvas.height = container.clientHeight + window.devicePixelRatio;
    canvas.style.width = container.clientWidth + "px";
    canvas.style.height = container.clientHeight + "px";
  };

  useEffect(() => {
    const node = canvasRef.current;
    const container = containerRef.current;
    const sandbox = new GlslCanvas(canvasRef.current);
    for (let k in props.setUniforms) {
      sandbox.setUniform(k, props.setUniforms[k]);
    }

    if(node && container) resizer(node, container);

    if(shader[0] !== "") {
      sandbox.load(shader[0]);
    }

    const handler = () => {
      if (node?.clientWidth !== container?.clientWidth || node?.clientHeight !== container?.clientHeight)
        if(canvasRef.current && containerRef.current) 
            resizer(canvasRef.current, containerRef.current);
    };

    window.addEventListener("resize", handler);

    return () => {
      window.removeEventListener("resize", handler);
    };
  });

  return (
      <div id="canvas-container">
        <div ref={containerRef} style={{ width: "70vh", height: "70vh", position: "absolute", left: "50%", top: "35vh", transform: "translate(-50%,-50%)" }}>
          <canvas ref={canvasRef}></canvas>
        </div>
      </div>
  );
};