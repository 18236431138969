import {connectWallet,mint} from '../Functions'
import { useGlobalState } from '../store'

const WalletConnect = () => {

    const [connectedAccount] = useGlobalState('connectedAccount')

    let page
    if (connectedAccount) {
        page = (
            <div>connected to: {connectedAccount}</div>
        );
    } else {
        page = <button type="button" onClick={connectWallet} className="main-mint-btn">CONNECT WALLET</button>;
    }

    return (
        <div className="wallet">{page}</div>
    )
}

export default WalletConnect